<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Cards Layout</title>
</head>
<body>
  <div class="container">
    <!-- Banner -->
    <div class="card banner">
    </div>

    <!-- Segunda fila -->
    <div class="row">
      <div class="card wide-card">
        
      </div>
      <div class="card small-card">
       
      </div>
      <div class="card small-card">
        
      </div>
    </div>

    <!-- Tercera fila -->
    <div class="row">
      <div class="card half-card">
        
      </div>
      <div class="card half-card">
        
      </div>
    </div>
  </div>
</body>
</html>
